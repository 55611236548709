import { useContext } from 'react';
import isNil from 'lodash-es/isNil';

import { CommonReturnProps } from 'interfaces/apiFetch';
import { METHODS } from 'utils/api';

import useFetch from '../useFetch';
import { fetchUserEndpoint } from 'api/user';
import { User } from 'interfaces/user';
import ApplicationContext from 'contexts/ApplicationContext';

interface FetchDelayReasonReturn extends CommonReturnProps {
  fetchUser: () => void;
  user: User | undefined;
}

const useFetchUser = (): FetchDelayReasonReturn => {
  const { setUser } = useContext(ApplicationContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (response: any) => {
    // Set User in application context
    if (!isNil(response)) {
      setUser(response);
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest,
    response
  } = useFetch({ onSuccessCallback });

  const fetchUser = () => {
    executeRequest({
      path: fetchUserEndpoint(),
      method: METHODS.GET,
      shouldExecuteRequest: true
    });
  };

  return {
    isFailed,
    isLoading,
    isSuccessful,
    error,
    fetchUser,
    user: response
  };
};

export default useFetchUser;
