import React, { useContext } from 'react';
import { Router, navigate, RouteComponentProps } from '@reach/router';
import { css } from 'aphrodite';
import isNil from 'lodash-es/isNil';

import { User, canViewLoanPlans, isCollectionsUser } from 'interfaces/user';

import Styles from 'components/common/styles/GlobalStyles';
import ErrorPage from 'components/common/errors/ErrorPage';
import ErrorBoundary from 'components/common/errors/ErrorBoundary';
import ApplicationContext from 'contexts/ApplicationContext';
import EnvironmentRibbon from 'components/common/EnvironmentRibbon';

export interface RouteItemProps {
  path: string;
  public: boolean;
  component: React.ReactNode;
  permitted: boolean;
}

export interface RouteProps {
  routes: RouteItemProps[];
}

type RouteTypeProps = RouteItemProps & { user: User | undefined };

// Lazy load components so that it won't
// load everything on initial load
const HomePage = React.lazy(() => import('components/pages/HomePage'));
const LoanPlansPage = React.lazy(() =>
  import('components/pages/LoanPlansPage')
);
const LoanPlanPage = React.lazy(() => import('components/pages/LoanPlanPage'));
const LoanPlanSimulatorPage = React.lazy(() =>
  import('components/pages/LoanPlanSimulatorPage')
);
const RestructuringPaymentPlansPage = React.lazy(() =>
  import('components/pages/RestructuringPaymentPlansPage')
);

export const fccBaseUri =
  process.env.REACT_APP_FCC_BASE_URI || 'http://localhost:3000'; // eslint-disable-line no-undef

const buildRoutes = (user: User | undefined): RouteItemProps[] => {
  if (isNil(user)) return [];

  // We can adjust this when needed. view_loan_status
  // is the based permission for users to view loan plan
  // page and loan plans page.
  const permitted = canViewLoanPlans(user);

  return [
    {
      path: '/',
      public: false,
      component: HomePage,
      permitted: permitted
    },
    {
      path: 'leads/:leadId/loan_plans',
      public: false,
      component: LoanPlansPage,
      permitted: permitted
    },
    {
      path: 'leads/:leadId/loan_plans/:loanPlanId',
      public: false,
      component: LoanPlanPage,
      permitted: permitted
    },
    {
      path: 'leads/:leadId/restructuring',
      public: false,
      component: RestructuringPaymentPlansPage,
      permitted: permitted && isCollectionsUser(user)
    },
    {
      path: 'leads/:leadId/loan_plans/:loanPlanId/computations',
      public: false,
      component: LoanPlanSimulatorPage,
      permitted: permitted
    }
  ];
};

export const unauthorize = () => {
  navigate(`${fccBaseUri}/central/admin_users/sign_in`);
};

// In case we will be showing not private route in the future
const PublicRoute = ({ component, ...rest }: RouteItemProps) => {
  const Component = component as React.FC<RouteComponentProps>;

  return <Component {...rest} />;
};

const PrivateRoute = ({
  component,
  path,
  permitted,
  user,
  ...rest
}: RouteTypeProps) => {
  const Component = component as React.FC<RouteComponentProps>;

  if (!isNil(user) && !permitted) {
    return (
      <div className={css(Styles.mainContainer)}>
        <ErrorPage
          type="forbidden"
          styleType="large"
          navigateTo={`${fccBaseUri}/central`}
          navigateToLabel="GO BACK TO FCC"
        />
      </div>
    );
  }

  return <Component path={path} {...rest} />;
};

const RoutesBuilder: React.FC = () => {
  const { user } = useContext(ApplicationContext);
  const routes = buildRoutes(user);

  // Prevents flicker showing of not found page
  if (routes.length === 0) return <div data-testid="routes-builder" />;

  return (
    <div data-testid="routes-builder">
      <EnvironmentRibbon />
      <ErrorBoundary>
        <Router>
          {buildRoutes(user).map((route: RouteItemProps, i: number) =>
            route.public ? (
              <PublicRoute key={i} {...route} />
            ) : (
              <PrivateRoute key={i} {...route} user={user} />
            )
          )}
          <ErrorPage type="pageNotFound" styleType="large" default />
        </Router>
      </ErrorBoundary>
    </div>
  );
};

export default RoutesBuilder;
