import { StyleSheet } from 'aphrodite';
import { fcblue, secondaryColor } from './GlobalStyles';

const styles = StyleSheet.create({
  tooltipContainer: {
    position: 'relative'
  },

  tooltip: {
    position: 'absolute',
    zIndex: 100,
    border: '2px',
    backgroundColor: secondaryColor,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#003a70',
    borderRadius: '6px',
    top: '30px',
    maxWidth: '400px'
  },

  leftToolTip: {
    right: '60px'
  },

  rightTooltip: {
    left: '40px'
  },

  tooltipHeader: {
    backgroundColor: fcblue,
    color: 'white',
    padding: '10px'
  },

  tooltipDescription: {
    display: 'inline-block',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    width: '300px',
    whiteSpace: 'normal'
  }
});

export default styles;
