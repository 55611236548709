import * as React from 'react';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import includes from 'lodash-es/includes';
import isUndefined from 'lodash-es/isUndefined';
import isNil from 'lodash-es/isNil';

interface Props {
  defaultState: Record<string, any>; // eslint-disable-line  @typescript-eslint/no-explicit-any
  submitAction: () => void;
  validationSchema?: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
  runValidationOnEveryChange?: boolean;
}

interface FormValues {
  [key: string]: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
}

interface DirectChangeField {
  field: string;
  value: any; // eslint-disable-line  @typescript-eslint/no-explicit-any
}

interface DirectClearError {
  field: string;
}

export type FormErrors = {
  [key: string]: string | undefined;
};

const useForm = ({
  defaultState,
  submitAction,
  validationSchema,
  runValidationOnEveryChange = false
}: Props) => {
  const [values, setValues] = useState<typeof defaultState>(defaultState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [formHaveChanged, setFormHaveChanged] = useState<boolean>(false);

  // Run submit action when
  // there are no errors and is submitted
  useEffect(() => {
    const formFields = Object.keys(values);
    const foundErrors = formFields.some(name => !!errors[name]);
    if (!foundErrors && isSubmitted) {
      submitAction();
      setIsSubmitted(false);
    }
  }, [errors, isSubmitted, submitAction, values]);

  const detectTouchedFieldPaths = (touchedFieldPaths: string[] = []) => {
    // cannot run any validation without a schema
    if (isUndefined(validationSchema)) {
      return touchedFieldPaths;
    }

    return [...touchedFieldPaths];
  };

  function clearErrors(touchedFieldNames: string[]) {
    const touchedFieldPaths = detectTouchedFieldPaths(touchedFieldNames);
    const updatedErrors = touchedFieldPaths.reduce(
      (acc, key) => ({
        ...acc,
        [key]: undefined
      }),
      Object(errors)
    );
    setErrors(updatedErrors);
  }

  const updateErrors = (
    error: yup.ValidationError,
    touchedFieldNames: string[]
  ) => {
    const detectedErrors = error.inner.reduce(
      (acc, err) => ({
        ...acc,
        [err.path]: err.message
      }),
      {}
    );
    const touchedFieldPaths = detectTouchedFieldPaths(touchedFieldNames);
    const updatedErrors = touchedFieldPaths.reduce(
      (acc, key: keyof typeof detectedErrors) => ({
        ...acc,
        [key]: detectedErrors[key]
      }),
      Object(errors)
    );
    setErrors(updatedErrors);
  };

  function runValidations(values: FormValues, touchedFieldNames: string[]) {
    if (isNil(validationSchema)) {
      return;
    }

    const keys = Object.keys(values);
    const data = keys.reduce((acc, fieldName) => {
      const value = values[fieldName] !== '' ? values[fieldName] : undefined;
      return {
        ...acc,
        [fieldName]: value
      };
    }, {});

    const affectedFieldNames = touchedFieldNames.filter(name =>
      includes(keys, name)
    );

    try {
      validationSchema.validateSync(data, { abortEarly: false });
      clearErrors(affectedFieldNames);
    } catch (error) {
      updateErrors(error, affectedFieldNames);
    }

    // Always set submitting to false
    // to be safe, it should only be set when submit is performed
    setIsSubmitted(false);
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  function assignUpdatedValue(fieldName: string, value: any) {
    return { ...Object(values), [fieldName]: value };
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  function handleChange(event: React.ChangeEvent<any>) {
    const { name, value, checked, type } = event.target;

    if (isNil(name)) {
      return;
    }

    const parsed = parseFloat(value);
    const val = !/number|range|checkbox/.test(type)
      ? value
      : !isNaN(parsed)
      ? parsed
      : /checkbox/.test(type)
      ? checked
      : '';
    const updatedValues = assignUpdatedValue(name, val);

    // We can run validation on every change
    if (runValidationOnEveryChange) {
      runValidations(updatedValues, [name]);
    }
    if (isSubmitted) {
      setIsSubmitted(false);
    }
    setFormHaveChanged(true);
    setValues(updatedValues);
  }

  // This is usable in cases wherein the form component has element
  // that will do changes in the fields also.
  // E.g icons click in logging calls.
  // This will also useful in using react-datepicker since it has it's
  // own onChange method inside that has multiple return
  // and we only need the return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChanges(fields: DirectChangeField | DirectChangeField[]) {
    let updatedValues = {};

    if (fields instanceof Array) {
      for (let i = 0; i < fields.length; i++) {
        const { field, value } = fields[i];
        updatedValues = { ...updatedValues, ...{ [field]: value } };
      }

      setValues({ ...values, ...updatedValues });
    } else {
      updatedValues = assignUpdatedValue(fields.field, fields.value);
      setValues(updatedValues);
    }

    // We can run validation on every change
    if (runValidationOnEveryChange) {
      if (fields instanceof Array) {
        runValidations(
          updatedValues,
          fields.map((f: DirectChangeField) => f.field)
        );
      } else {
        runValidations(updatedValues, [fields.field]);
      }
    }

    if (isSubmitted) {
      setIsSubmitted(false);
    }
  }

  // Will be useful for clearing validation error directly
  function directClearError(fields: DirectClearError | DirectClearError[]) {
    let updatedErrors = {};

    if (fields instanceof Array) {
      for (let i = 0; i < fields.length; i++) {
        const { field } = fields[i];
        updatedErrors = { ...updatedErrors, ...{ [field]: undefined } };
      }
    } else {
      updatedErrors = { ...errors, [fields.field]: undefined };
    }

    setErrors(updatedErrors);
  }

  function clearAllErrors() {
    setErrors({});
  }

  function resetForm() {
    setValues(defaultState);
    setFormHaveChanged(false);
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  function handleSubmit() {
    runValidations(values, Object.keys(values));
    setIsSubmitted(true);
  }

  function hasErrors(): boolean {
    return (
      Object.entries(errors).filter(([, v]) => {
        return !isNil(v);
      }).length !== 0
    );
  }

  return {
    handleChange,
    handleChanges,
    handleSubmit,
    directClearError,
    clearAllErrors,
    setErrors,
    resetForm,
    formHaveChanged,
    setFormHaveChanged,
    values,
    errors,
    hasErrors: hasErrors()
  };
};

export default useForm;
