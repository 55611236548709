import React, { useEffect, useContext } from 'react';
import isNil from 'lodash-es/isNil';
import { css } from 'aphrodite';
import { datadogRum } from '@datadog/browser-rum';
import { isUndefined } from 'lodash-es';

import { errorType } from 'utils/error';

import Styles from 'components/common/styles/GlobalStyles';
import BuildRoutes, { unauthorize } from './RoutesHelper';
import useFetchUser from 'hooks/user/useFetchUser';
import SuspenseLoader from 'components/common/loaders/SuspenseLoader';
import ErrorPage from 'components/common/errors/ErrorPage';
import ApplicationContext from 'contexts/ApplicationContext';

const Root = () => {
  const { fetchUser, isLoading, error } = useFetchUser();
  const { isUnauthorized } = useContext(ApplicationContext);

  // Fetch user information with permissions
  useEffect(fetchUser, []);

  if (isLoading) {
    return <SuspenseLoader dataTestId="fetch-user-loader" />;
  }

  // When user fetch or any other api calls
  // returns unauthorize, navigate back to fcc.
  // And don't render anything anymore.
  if (isUnauthorized) {
    unauthorize();
    return null;
  }

  if (!isNil(error)) {
    return (
      <div className={css(Styles.mainContainer)}>
        <ErrorPage type={errorType(error)} styleType="large" />;
      </div>
    );
  }

  // Let's only record the session of authenticated user to DataDog.
  /* eslint-disable-next-line no-undef */
  if (!isUndefined(process.env.REACT_APP_DD_CLIENT_TOKEN)) {
    datadogRum.startSessionReplayRecording();
  }

  return (
    <div data-testid="root-component">
      <BuildRoutes />
    </div>
  );
};

export default Root;
