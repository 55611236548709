import isUndefined from 'lodash-es/isUndefined';
import includes from 'lodash-es/includes';

export enum ERROR_NAMES {
  CLIENT_ERROR = 'CLIENT_ERROR',
  PROTECTED_ERROR = 'PROTECTED_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND',
  FORBIDDEN_ERROR = 'FORBIDDEN',
  UNPROCESSABLE_ENTITY_ERROR = 'UNPROCESSABLE_ENTITY_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export type ErrorType =
  | 'forbidden'
  | 'notFound'
  | 'pageNotFound'
  | 'serverError'
  | 'networkError'
  | 'unknownError';

export const errorType = (error: HttpRequestError) => {
  let type: ErrorType;

  // There will be instances wherein
  // our app will catches errors outside
  // the responses Rails will be providing.
  // For intance, client error and errors from
  // Backend's rack attack
  const errorNames = Object.values(ERROR_NAMES);
  if (!includes(errorNames, error.name)) {
    return (type = 'networkError');
  }

  switch (error.name) {
    case ERROR_NAMES.CLIENT_ERROR:
      type = 'networkError';
      break;
    case ERROR_NAMES.NOT_FOUND_ERROR:
      type = 'notFound';
      break;
    case ERROR_NAMES.SERVER_ERROR:
      type = 'serverError';
      break;
    case ERROR_NAMES.FORBIDDEN_ERROR:
      type = 'forbidden';
      break;
    default:
      type = 'unknownError';
  }
  return type;
};

export class HttpRequestError<T = {}> extends Error {
  public readonly response: Response;
  public readonly payload: T | undefined;
  public readonly status: number;
  public readonly errorMessage: string | string[];

  private getErrorName = (status: number) => {
    if (status >= 400 && status < 500) {
      if (status === 404) {
        return ERROR_NAMES.NOT_FOUND_ERROR;
      } else if (status === 403) {
        return ERROR_NAMES.FORBIDDEN_ERROR;
      }
      return ERROR_NAMES.CLIENT_ERROR;
    } else if (status >= 500 && status < 600) {
      return ERROR_NAMES.SERVER_ERROR;
    } else {
      return ERROR_NAMES.UNKNOWN_ERROR;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private constructErrors = (payload?: any) => {
    if (isUndefined(payload)) return null;

    if (payload instanceof Array) return payload;
    if (payload instanceof Object) return payload.error;

    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public constructor(response: Response, payload?: any) {
    super(`${response.status}: ${response.statusText}`);

    this.response = response;
    this.errorMessage = this.constructErrors(payload);
    this.payload = payload;
    this.status = response.status;
    this.name = this.getErrorName(response.status);
  }
}

export const handleErrorsIfNeeded = <Payload>(
  response: Response,
  payload?: Payload
) => {
  if (response.status < 400) {
    return null;
  }

  throw new HttpRequestError(response, payload);
};
