import LoanPlanComputationModal from '../features/loan_plan/LoanPlanComputationModal';
import LoanPlanRestructureConfirmationModal from '../features/loan_plan/LoanPlanRestructureConfirmationModal';
import ResetRestructuringFormModal from '../features/restructuring/ResetFormModal';
import CancelPaymentPlanModal from '../features/restructuring/CancelPaymentPlanModal';
import ReturnToLoanPlanPageModal from '../features/restructuring/ReturnToLoanPlanPageModal';
import SavePaymentPlanModal from '../features/restructuring/SavePaymentPlanModal';
import GenerateLoanPlan from '../features/restructuring/GenerateLoanPlanModal';

export enum ModalComponent {
  LOAN_PLAN_COMPUTATION_MODAL = 'LOAN_PLAN_COMPUTATION_MODAL',
  LOAN_PLAN_RESTRUCTURE_CONFIRMATION_MODAL = 'LOAN_PLAN_RESTRUCTURE_CONFIRMATION_MODAL',
  RESET_RESTRUCTURING_CONFIRMATION_MODAL = 'RESET_RESTRUCTURING_CONFIRMATION_MODAL',
  CANCEL_PAYMENT_PLAN_MODAL = 'CANCEL_PAYMENT_PLAN_MODAL',
  RETURN_TO_LOAN_PLAN_PAGE_MODAL = 'RETURN_TO_LOAN_PLAN_PAGE_MODAL',
  SAVE_PAYMENT_PLAN_MODAL = 'SAVE_PAYMENT_PLAN_MODAL',
  GENERATE_LOAN_PLAN_MODAL = 'GENERATE_LOAN_PLAN_MODAL'
}

interface ModalComponents {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MODAL_COMPONENTS: ModalComponents = {
  [ModalComponent.LOAN_PLAN_COMPUTATION_MODAL]: LoanPlanComputationModal,
  [ModalComponent.LOAN_PLAN_RESTRUCTURE_CONFIRMATION_MODAL]: LoanPlanRestructureConfirmationModal,
  [ModalComponent.RESET_RESTRUCTURING_CONFIRMATION_MODAL]: ResetRestructuringFormModal,
  [ModalComponent.CANCEL_PAYMENT_PLAN_MODAL]: CancelPaymentPlanModal,
  [ModalComponent.RETURN_TO_LOAN_PLAN_PAGE_MODAL]: ReturnToLoanPlanPageModal,
  [ModalComponent.SAVE_PAYMENT_PLAN_MODAL]: SavePaymentPlanModal,
  [ModalComponent.GENERATE_LOAN_PLAN_MODAL]: GenerateLoanPlan
};

export default MODAL_COMPONENTS;
