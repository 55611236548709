import React from 'react';
import ApplicationContext from 'contexts/ApplicationContext';
import useApplicationContextUpdater from 'hooks/application/useApplicationContextUpdater';

interface Props {
  children: React.ReactElement;
}

const ApplicationProvider: React.FC<Props> = ({ children }) => {
  const applicationUpdater = useApplicationContextUpdater();
  return (
    <ApplicationContext.Provider value={applicationUpdater}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
