export const restructuringEndpoint = (
  leadId: string,
  loanPlanIds: string | string[] | undefined | null
) => {
  return `/leads/${leadId}/restructuring_payment_plans/${loanPlanIds}`;
};

export const savePaymentPlanEndpoint = (leadId: string) => {
  return `/leads/${leadId}/restructuring_payment_plans`;
};

export const updatePaymentPlanEndpoint = (
  leadId: string,
  paymentPlanId: number | string
) => {
  return `/leads/${leadId}/restructuring_payment_plans/${paymentPlanId}`;
};

export const generateNewLoanPlanEndpoint = (
  leadId: string,
  paymentPlanId: number | undefined
) => {
  return `/leads/${leadId}/restructuring_payment_plans/${paymentPlanId}/restructure`;
};

export const cancelPaymentPlanEndpoint = (
  leadId: string,
  loanPlanIds: string
) => {
  return `/leads/${leadId}/restructuring_payment_plans/${loanPlanIds}`;
};
