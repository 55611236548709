import { format, differenceInCalendarDays } from 'date-fns';

export const formatDate = (date: Date, dateFormat: string) => {
  if (date) {
    return format(date, dateFormat);
  }

  return '';
};

export const loanDurationInText = (
  startDate: string,
  endDate: number
): string => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const stringStart = format(start, 'MMM dd, yyyy');
  const stringEnd = format(end, 'MMM dd, yyyy');

  return `${stringStart} - ${stringEnd}`;
};

export const loanDurationInDays = (
  startDate: string,
  endDate: string
): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return differenceInCalendarDays(end, start);
};
