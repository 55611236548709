import { SelectOption } from './global';

export type Status =
  | 'paid'
  | 'undue'
  | 'partially paid'
  | 'paid rescheduling fee'
  | 'overdue'
  | 'void action'
  | 'Payment'
  | 'Due Today'
  | 'Work on this'
  | 'Upcoming Payment Due'
  | 'underpaid'
  | 'overpaid'
  | 'ontime'
  | 'Waived'
  | 'Data Fixed'
  | 'Settled'
  | 'data_fix'
  | 'payment'
  | 'waived'
  | 'In Restructuring'
  | 'Restructured'
  | undefined;

export interface Transaction {
  amount: number;
  date: string;
  fee: NumberOrString;
  principal: NumberOrString;
  interest: NumberOrString;
  kind?: Status;
}

export interface LoanPlanTransaction {
  expected: Transaction[];
  actual: Transaction[];
}

export interface Payment {
  principal: number;
  interest: number;
  fee: number;
  total: number;
}

export interface Receivable {
  id: string;
  clearance_expectation: string;
  clearance_reason: string;
  due_on: string;
}

export interface PaymentSchedule {
  due_date: string;
  fee: number;
  interest: number;
  principal: number;
  status: Status;
  total: number;
  date_paid: string;
  updated_date: string;
}

export interface PaymentState {
  remaining: number;
  paid: number;
}

export type NumberOrString = string | number;

export interface LatestLoanStatus {
  status: Status;
  fee_paid: NumberOrString;
  fee_remaining: NumberOrString;
  fee_data_fixed: NumberOrString;
  fee_waived: NumberOrString;
  interest_paid: NumberOrString;
  interest_remaining: NumberOrString;
  interest_data_fixed: NumberOrString;
  interest_waived: NumberOrString;
  principal_paid: NumberOrString;
  principal_remaining: NumberOrString;
  principal_data_fixed: NumberOrString;
  principal_waived: NumberOrString;
  sums_fee: NumberOrString;
  sums_interest: NumberOrString;
  sums_principal: NumberOrString;
  sums_total: NumberOrString;
  total_paid: NumberOrString;
  total_remaining: NumberOrString;
  total_data_fixed: NumberOrString;
  total_waived: NumberOrString;
  expected_ending_balance: NumberOrString;
}

export interface LoanDetails {
  amount: number;
  interest_rate: number;
  lead_id: string;
  id: string;
  product_type: string;
  repayment_type: string;
  loan_duration: string;
  processing_fee: number;
  processing_fee_rate: number;
  fee_rate: number;
  disbursed_amount: number;
  total_terms_in_weeks: number;
  bank: string;
  period_count: number;
  start_date: string;
  final_repayment_date: string;
  term_length: number;
  late_fee_percentage: number;
  snapshot: LatestLoanStatus;
  manual_snapshot: LatestLoanStatus;
  schedule_snapshot: PaymentSchedule[];
  manual_schedule_snapshot: PaymentSchedule[];
  receivables: Receivable[];
  settled: boolean;
  transactions: LoanPlanTransaction;
  restructuring_eligible: boolean;
  in_restructuring_process: boolean;
  newly_restructured: boolean;
  restructured_predecessor_ids: string;
  rlp_initiator: string | null;
}

export interface RescheduledPayment {
  amount: number;
  outstanding_balance: number;
  partial_payment: number;
  to_be_vs_as_is?: number;
}

export interface SimulatedRescheduledPayment {
  current: RescheduledPayment;
  reschedule: RescheduledPayment;
}

export interface LoanPlanContext {
  loanPlan?: LoanDetails | undefined;
  setLoanPlan: (loanPlan: LoanDetails | undefined) => void;
  lateFeePercentage: number;
  setLateFeePercentage: (percentage: number) => void;
  refetchLoanPlan: boolean;
  setRefetchLoanPlan: (state: boolean) => void;
  simulatedRescheduledPayment?: SimulatedRescheduledPayment | undefined;
  setSimulatedRescheduledPayment: (
    payment: SimulatedRescheduledPayment | undefined
  ) => void;
}

// we can't put values less than zero
export const MIN_LATE_FEE_PERCENTAGE = 0.0;

// Currently the max and default percentage of late fees is 2
// We can't update this here or by setting and fetching it in the API.
export const MAX_LATE_FEE_PERCENTAGE = 0.02;

export enum RESCHEDULE_PAYMENT_TYPE {
  LATE = 'late',
  EARLY = 'early'
}

export enum PERIOD_COUNT {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  TWELVE = '12',
  EIGHTEEN = '18',
  TWENTY_FOUR = '24'
}

export const PERIOD_COUNT_OPTIONS: SelectOption[] = [
  { label: 'Select', value: '' },
  { label: '1', value: PERIOD_COUNT.ONE },
  { label: '2', value: PERIOD_COUNT.TWO },
  { label: '3', value: PERIOD_COUNT.THREE },
  { label: '4', value: PERIOD_COUNT.FOUR },
  { label: '5', value: PERIOD_COUNT.FIVE },
  { label: '6', value: PERIOD_COUNT.SIX },
  { label: '12', value: PERIOD_COUNT.TWELVE },
  { label: '18', value: PERIOD_COUNT.EIGHTEEN },
  { label: '24', value: PERIOD_COUNT.TWENTY_FOUR }
];

export enum SIMULATOR_SEQUENCE {
  ACTUAL_SEQUENCE = 'actual_sequence',
  BALANCED_SEQUENCE = 'balanced_sequence',
  UNBALANCED_SEQUENCE = 'unbalanced_sequence',
  EXPECTED_SEQUENCE = 'expected_sequence'
}

export enum FREQUENCY {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  FORTHNIGHTLY = 'fortnightly'
}

export enum LOAN_PLAN_STATE {
  UNDUE = 'undue',
  ACTIVE = 'active',
  OVERDUE = 'overdue',
  SETTLED = 'settled',
  RESTRUCTURED = 'restructured',
  IN_RESTRUCTURING_PROCESS = 'in_restructuring_process'
}

export const SEQUENCE_OPTIONS: SelectOption[] = [
  { label: 'Select', value: '' },
  { label: 'Actual Sequence', value: SIMULATOR_SEQUENCE.ACTUAL_SEQUENCE },
  {
    label: 'Balanced Sequence',
    value: SIMULATOR_SEQUENCE.BALANCED_SEQUENCE
  },
  {
    label: 'Unalanced Sequence',
    value: SIMULATOR_SEQUENCE.UNBALANCED_SEQUENCE
  },
  { label: 'Expected Sequence', value: SIMULATOR_SEQUENCE.EXPECTED_SEQUENCE }
];

export const FREQUENCY_OPTIONS: SelectOption[] = [
  { label: 'Select', value: '' },
  { label: 'Monthly', value: FREQUENCY.MONTHLY },
  { label: 'Weekly', value: FREQUENCY.WEEKLY },
  { label: 'Fortnightly', value: FREQUENCY.FORTHNIGHTLY }
];

export const RESTRUCTURING_FILTER_OPTIONS: SelectOption[] = [
  { label: 'All Loan Plans', value: '' },
  {
    label: 'In Restructuring',
    value: LOAN_PLAN_STATE.IN_RESTRUCTURING_PROCESS
  },
  { label: 'Active', value: LOAN_PLAN_STATE.ACTIVE },
  { label: 'Undue', value: LOAN_PLAN_STATE.UNDUE },
  { label: 'Overdue', value: LOAN_PLAN_STATE.OVERDUE },
  { label: 'Settled', value: LOAN_PLAN_STATE.SETTLED },
  { label: 'Restructured', value: LOAN_PLAN_STATE.RESTRUCTURED }
];

export interface FilterValues {
  filter: LOAN_PLAN_STATE | null;
}

export interface SimulatorValues {
  frequency: FREQUENCY | null;
  sequence: SIMULATOR_SEQUENCE | null;
  period_count: PERIOD_COUNT | null;
  current_date: Date | string | null;
  start_date: Date | string | null;
  final_repayment_date: Date | string | null;
  amount: number | null;
  interest_rate: number | null;
  fee_rate: number | null;
  expected_transactions_adjustments: string;
  actual_transactions: string;
  arbitrary_fee: number | null;
}

export const EmptyLatestLoanStatus: LatestLoanStatus = {
  status: undefined,
  fee_paid: '-----',
  fee_remaining: '-----',
  interest_paid: '-----',
  interest_remaining: '-----',
  principal_paid: '-----',
  principal_remaining: '-----',
  sums_fee: '-----',
  sums_interest: '-----',
  sums_principal: '-----',
  sums_total: '-----',
  total_paid: '-----',
  total_remaining: '-----',
  expected_ending_balance: 0,
  interest_data_fixed: '-----',
  interest_waived: '-----',
  fee_data_fixed: '-----',
  fee_waived: '-----',
  principal_data_fixed: '-----',
  principal_waived: '-----',
  total_data_fixed: '-----',
  total_waived: '-----'
};
