import qs from 'querystring';
import isNil from 'lodash-es/isNil';
import { formatDate } from './date';

export const defaultValues = {
  frequency: null,
  sequence: null,
  current_date: null,
  start_date: null,
  final_repayment_date: null,
  period_count: null,
  amount: null,
  interest_rate: null,
  fee_rate: null,
  arbitrary_fee: null,
  expected_transactions_adjustments: '',
  actual_transactions: ''
};

export const handleDate = (date: Date | undefined) => {
  return !isNil(date) ? formatDate(date, 'yyyy-MM-dd') : null;
};

export const defaultFormValues = (urlParams: string | undefined) => {
  const convertDate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parsed: Record<string, any>,
    attribute: string
  ) => {
    if (!isNil(parsed[attribute])) {
      parsed = {
        ...parsed,
        [attribute]: new Date(parsed[attribute])
      };
    }

    return parsed;
  };

  if (!isNil(urlParams) && urlParams !== '') {
    const params = urlParams.substring(urlParams.indexOf('?') + 1);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let parsed: any = qs.parse(params);

    parsed = {
      ...defaultValues,
      ...parsed
    };

    parsed = convertDate(parsed, 'current_date');
    parsed = convertDate(parsed, 'start_date');
    parsed = convertDate(parsed, 'final_repayment_date');

    return parsed;
  }

  return defaultValues;
};
