import { useState, useCallback } from 'react';
import { ModalContext, ModalProps } from 'interfaces/modal';
import { ModalComponent } from 'components/modals/ModalComponents';

const useModalContextUpdater = (): ModalContext => {
  const [type, setUpdatedType] = useState<ModalComponent>();
  const [modalProps, setUpdatedModalProps] = useState<ModalProps>();

  const setType = useCallback(
    (type: ModalComponent | undefined): void => {
      setUpdatedType(type);
    },
    [setUpdatedType]
  );

  const setModalProps = useCallback(
    (props: ModalProps): void => {
      setUpdatedModalProps(props);
    },
    [setUpdatedModalProps]
  );

  return {
    type,
    modalProps,
    setType,
    setModalProps
  };
};

export default useModalContextUpdater;
