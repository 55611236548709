import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { useState } from 'react';
import { Company } from 'interfaces/company';
import { ApplicationContext } from 'interfaces/application';
import { User } from 'interfaces/user';

const useApplicationContextUpdater = (): ApplicationContext => {
  const [company, setUpdatedCompany] = useState<Company>();
  const [user, setUpdatedUser] = useState<User>();
  const [isUnauthorized, setUpdatedIsUnauthorized] = useState<boolean>(false);

  const setCompany = (company: Company): void => {
    setUpdatedCompany(company);
  };

  const setUser = (user: User): void => {
    // Set authenticate user for RUM
    datadogRum.setUser({
      id: user.id.toString(),
      name: user.name,
      email: user.email
    });

    setUpdatedUser(user);
  };

  const setIsUnauthorized = (): void => {
    // Remove user on RUM
    datadogRum.removeUser();

    setUpdatedIsUnauthorized(true);
  };

  const mUser = React.useMemo(() => user, [user]);

  return {
    company,
    setCompany,
    user: mUser,
    setUser,
    isUnauthorized,
    setIsUnauthorized
  };
};

export default useApplicationContextUpdater;
