import React from 'react';
import { css } from 'aphrodite';
import { isNil } from 'lodash-es';
import { navigate } from '@reach/router';

import { CommonModalProps } from 'interfaces/modal';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';
import RaisedButton from 'components/common/buttons/RaisedButton';
import useSavePreview from 'hooks/restructuring/useSavePreview';
import Notice from 'components/common/Notice';
import { RestructuringFormValues } from 'interfaces/restructuring';

export interface OwnProps {
  action: 'create' | 'update';
  savePayload: RestructuringFormValues;
  leadId: string;
  paymentPlanId?: number;
}

type Props = OwnProps & CommonModalProps;

const SavePaymentPlanModal: React.FC<Props> = ({
  action,
  hideModal,
  savePayload,
  paymentPlanId,
  leadId
}) => {
  const {
    isLoading,
    error,
    savePaymentPlan,
    updatePaymentPlan
  } = useSavePreview({
    onSuccessCallback
  });

  function onSuccessCallback() {
    navigate(`/leads/${leadId}/loan_plans`);
    hideModal();
  }

  function onConfirmClick() {
    if (action === 'create') {
      savePaymentPlan(savePayload, leadId);
    } else {
      updatePaymentPlan(savePayload, leadId, paymentPlanId);
    }
  }

  function handleOnClose(event: React.MouseEvent<HTMLElement>) {
    if (isLoading) return;
    hideModal(event);
  }

  return (
    <div
      onClick={handleOnClose}
      className={`ds-modal _open ${css(GlobalStyles.modal)}`}
      data-testid="cancel-payment-plan-confirmation-modal"
    >
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-db600">
            <h1 className="subhead -white">Save RLP Payment Plan Preview</h1>
          </section>
          <ModalTopCloseButton hideModal={handleOnClose} />

          {!isNil(error) && (
            <div className="_marginLeft--xxxlarge _marginRight--xxxlarge _marginTop--normal">
              <Notice
                type="error"
                message={
                  'Ooops! An error occured. Please try again. If problem persist contact support.'
                }
                error={error}
              />
            </div>
          )}

          <section className="cardSection">
            <div className="_padding--large _paddingTop--none">
              <p className="label _alignCenter _fontSize--xlarge _bold">
                Are you sure you want to save the details? The changes you made
                will be applied to the RLP Payment Plan Preview.
              </p>
            </div>
          </section>
          <div className="_padding--normal">
            <div className="ds-buttonGroup _spacedButtons _justifyContent-center">
              <span className="_marginRight--small">
                <RaisedButton
                  type="danger"
                  dataTestId="cancel-button"
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    handleOnClose(event);
                  }}
                >
                  Cancel
                </RaisedButton>
              </span>
              <span className="_marginRight--small">
                <RaisedButton
                  type="primary"
                  dataTestId="save-preview-button"
                  isLoading={isLoading}
                  disabled={isLoading}
                  action={onConfirmClick}
                >
                  {"Yes, I'm sure"}
                </RaisedButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavePaymentPlanModal;
