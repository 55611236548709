import React, { useEffect, useRef, useState } from 'react';
import isNil from 'lodash-es/isNil';
import { css } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';

import Styles from './styles/tooltipStyles';

// TODO: Handle top and bottom positions
// as well in the future.
// Also, please take not that this tooltip
// is not handling positions dynamically.
// That is why we need to pass position as
// a prop. It will be really awesome to make it
// dynamic. For now this is enough.

interface Props {
  title: string;
  description: string;
  children: React.ReactNode | React.ReactNode[];
  position: 'right' | 'left';
  dataTestId: string;
}

const Tooltip: React.FC<Props> = ({
  title,
  children,
  description,
  position,
  dataTestId
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [visible, setVisible] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseover = ({ target }: { target: any }) => {
    if (!isNil(ref) && !isNil(ref.current)) {
      // inside hover
      if (ref.current.contains(target)) {
        return;
      }
    }
    // when not hovered
    setVisible(false);
  };

  // handle when mounted and unmounted
  useEffect(() => {
    document.addEventListener('mouseover', handleMouseover);
    return () => {
      document.removeEventListener('mouseover', handleMouseover);
    };
  }, []);

  return (
    <div
      className={css(Styles.tooltipContainer)}
      data-testid={`${dataTestId}-tooltip-content`}
      ref={ref}
      onMouseOver={() => setVisible(true)}
    >
      {children}
      {visible && (
        <div
          data-testid="tooltip"
          className={css(
            Styles.tooltip,
            position === 'left' ? Styles.leftToolTip : Styles.rightTooltip
          )}
        >
          <div className={css(Styles.tooltipHeader)}>{title}</div>
          <div className={`${css(Styles.tooltipDescription)} _padding--normal`}>
            {ReactHtmlParser(description)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
