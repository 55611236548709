/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect, useCallback, useContext } from 'react';
import isNil from 'lodash-es/isNil';

import { handleErrorsIfNeeded, HttpRequestError } from 'utils/error';
import { usePrevious } from './usePrevious';
import { CommonProps } from 'interfaces/apiFetch';

import Api, { METHODS } from 'utils/api';
import ApplicationContext from 'contexts/ApplicationContext';

interface ReturnProps {
  isLoading: boolean;
  isSuccessful: boolean;
  isFailed: boolean;
  response: any;
  error: HttpRequestError | undefined;
  executeRequest: (states: RequestInitialState) => void;
  updateResponse: (response: any) => void;
}

interface RequestInitialState {
  shouldExecuteRequest?: boolean;
  payload?: Record<string, any>;
  path: string;
  method: METHODS;
}

const defaultRequestInitialState: RequestInitialState = {
  path: '',
  payload: {},
  method: METHODS.GET,
  shouldExecuteRequest: false
};

interface OwnProps {
  requestInitialState?: RequestInitialState;
}

type Props = OwnProps & CommonProps;

const useFetch = ({
  requestInitialState = defaultRequestInitialState,
  onSuccessCallback,
  onFailCallback
}: Props = {}): ReturnProps => {
  const { setIsUnauthorized } = useContext(ApplicationContext);

  // States
  const [requestStates, setRequestStates] = useState<RequestInitialState>(
    defaultRequestInitialState
  );
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<HttpRequestError | undefined>(undefined);

  const resetStates = () => {
    setIsSuccessful(false);
    setIsFailed(false);
    setResponse(null);
    setError(undefined);
  };

  // this will fire whenever components trigger
  // request by changing shouldExecuteRequest to true
  useEffect(() => {
    // Abort controller helps us avoiding memory leaks
    // on instances where for example components unmounts
    // and the fetch request is not yet done
    // NOTE: This might not work on old browser versions
    // Make sure that the users are using latest versions
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (isLoading) {
      // Make sure to reset this states first
      // before doing another fetch request to
      // make sure it's returning an accurate states
      resetStates();

      const doFetch = async () => {
        const { path, payload, method } = requestStates;
        try {
          const res = await Api.doFetch(path, payload, method);
          const json = await res.json();

          // This will check if the response succeeds or failed
          // then if error transform error and throw it
          // catch here should handle the error and set failed state
          handleErrorsIfNeeded(res, json);

          if (!signal.aborted) {
            setIsSuccessful(true);
            setResponse(json);
          }
        } catch (e) {
          if (!signal.aborted) {
            setIsFailed(true);
            setError(e);
          }
        } finally {
          if (!signal.aborted) {
            setIsLoading(false);
          }
        }
      };
      doFetch();
    }
    return () => abortController.abort();
  }, [isLoading, requestStates]);

  // ########################################################################
  // ###################### Handle success callback #########################
  // ########################################################################
  const isPrevResponse = usePrevious(response);
  useEffect(() => {
    if (isSuccessful && isNil(isPrevResponse) && !isNil(response)) {
      if (!isNil(onSuccessCallback)) {
        onSuccessCallback(response);
      }
    }
  }, [isSuccessful, isPrevResponse, response, onSuccessCallback]);

  // ########################################################################
  // ############### Set unauthorize error if api returns 401 ###############
  // ############### And app will automatically #############################
  // ############### redirect the user to fcc login page ####################
  // ########################################################################
  const prevError = usePrevious(error);
  useEffect(() => {
    if (isNil(prevError) && !isNil(error)) {
      if (!isNil(onFailCallback)) {
        onFailCallback();
      }

      if (error.status === 401) {
        setIsUnauthorized();
      }
    }
  }, [error, setIsUnauthorized, onFailCallback, prevError]);

  const executeRequest = useCallback(
    (states: RequestInitialState) => {
      setRequestStates({ ...requestInitialState, ...states });
      setIsLoading(true);
    },
    [requestInitialState]
  );

  const updateResponse = useCallback((response: any) => {
    setResponse(response);
  }, []);

  return {
    response,
    error,
    isLoading,
    isSuccessful,
    isFailed,
    executeRequest,
    updateResponse
  };
};

export default useFetch;
