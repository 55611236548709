import React, { useState, useEffect } from 'react';
import isNil from 'lodash-es/isNil';
import isUndefined from 'lodash-es/isUndefined';
import { css } from 'aphrodite';

import GlobalStyles, { borderColor } from '../styles/GlobalStyles';

interface OwnProps {
  error?: string;
  clearable?: boolean;
  showError?: boolean;
  label?: string;
  onClearAction?: () => void;
}

export type Props = OwnProps & React.HTMLProps<HTMLInputElement>;
type InputValue = string | number | readonly string[] | undefined;

const TextInput: React.FC<Props> = props => {
  const {
    error,
    showError,
    onChange,
    onClearAction,
    label,
    value,
    clearable,
    disabled,
    name,
    ...attr
  } = props;

  const [inputValue, setInputValue] = useState<InputValue>(
    isNil(value) ? '' : value
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clearValue = (event: any): void => {
    if (!isUndefined(event)) {
      event.preventDefault();
    }
    if (!isUndefined(onChange)) {
      onChange(event);
    }
    if (!isNil(onClearAction)) {
      onClearAction();
    }
    setInputValue('');
  };

  const renderClearIcon = (): React.ReactElement | null => {
    if (!clearable || isNil(inputValue) || inputValue === '') return null;

    return (
      <span
        data-testid="clear-icon"
        onClick={clearValue}
        className={`_marginRight--small -secondary ${css(
          GlobalStyles.clearInput
        )}`}
      >
        &times;
      </span>
    );
  };

  const errorClass = !isNil(error) ? '_error' : '';
  let inputClasses = css(GlobalStyles.borderColor);
  if (error) {
    inputClasses = css(GlobalStyles.inputWithError);
  }

  return (
    <>
      <label
        className={`ds-input position-relative _marginBottom--xxxsmall ${errorClass}`}
      >
        {!isNil(label) && <span>{label}</span>}
        <input
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChange(event);
          }}
          name={name}
          type="text"
          disabled={disabled}
          value={inputValue}
          className={inputClasses}
          style={disabled ? { backgroundColor: borderColor } : {}}
          {...attr}
        />
        {renderClearIcon()}
      </label>
      {!isNil(error) && showError && (
        <span className="-danger _marginBottom--normal">{error}</span>
      )}
    </>
  );
};

export default TextInput;
