import React from 'react';
import { css } from 'aphrodite';
import { navigate } from '@reach/router';
import { isNil } from 'lodash-es';

import { CommonModalProps } from 'interfaces/modal';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';
import RaisedButton from 'components/common/buttons/RaisedButton';
import useCancelPaymentPlan from 'hooks/restructuring/useCancelPaymentPlan';
import Notice from 'components/common/Notice';

export interface OwnProps {
  loanPlanIds: string;
  leadId: string;
}

type Props = OwnProps & CommonModalProps;

const RestructuringResetConfirmationModal: React.FC<Props> = ({
  hideModal,
  loanPlanIds,
  leadId
}) => {
  const { isLoading, cancelPaymentPlan, error } = useCancelPaymentPlan({
    onSuccessCallback
  });

  function onSuccessCallback() {
    hideModal();
    navigate(`/leads/${leadId}/loan_plans`);
  }

  function onConfirmClick() {
    if (isNil(loanPlanIds)) return;

    cancelPaymentPlan(loanPlanIds, leadId);
  }

  function handleOnClose(event: React.MouseEvent<HTMLElement>) {
    if (isLoading) return;

    hideModal(event);
  }

  return (
    <div
      onClick={handleOnClose}
      className={`ds-modal _open ${css(GlobalStyles.modal)}`}
      data-testid="cancel-payment-plan-confirmation-modal"
    >
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-db600">
            <h1 className="subhead -white">Cancel Saved Payment Plan</h1>
          </section>
          <ModalTopCloseButton hideModal={handleOnClose} />

          {!isNil(error) && (
            <div className="_marginLeft--xxxlarge _marginRight--xxxlarge _marginTop--normal">
              <Notice
                type="error"
                message={
                  'Ooops! An error occured. Please try again. If problem persist contact support.'
                }
                error={error}
              />
            </div>
          )}

          <section className="cardSection">
            <div className="_padding--large _paddingTop--none">
              <p className="label _alignCenter _fontSize--xlarge _bold">
                Are you sure you want to cancel this RLP Payment Plan preview?
                Your selected loan plans will revert to their original state and
                will accrue interest and fees normally.
              </p>
            </div>
          </section>
          <div className="_padding--normal">
            <div className="ds-buttonGroup _spacedButtons _justifyContent-center">
              <span className="_marginRight--small">
                <RaisedButton
                  type="danger"
                  dataTestId="cancel-button"
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    handleOnClose(event);
                  }}
                >
                  Cancel
                </RaisedButton>
              </span>
              <span className="_marginRight--small">
                <RaisedButton
                  type="primary"
                  dataTestId="cancel-confirm-button"
                  isLoading={isLoading}
                  disabled={isLoading}
                  action={onConfirmClick}
                >
                  {"Yes, I'm sure"}
                </RaisedButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestructuringResetConfirmationModal;
