import React from 'react';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';
import { css } from 'aphrodite';

import { defaultFormValues, handleDate } from 'utils/loanSimulator';
import { CommonModalProps } from 'interfaces/modal';
import {
  SimulatorValues,
  SEQUENCE_OPTIONS,
  PERIOD_COUNT_OPTIONS,
  FREQUENCY_OPTIONS
} from 'interfaces/loan';

import DynamicSelect, { Value } from 'components/common/selects/DynamicSelect';
import GlobalStyles from 'components/common/styles/GlobalStyles';
import ReactDatePicker from 'components/common/inputs/Datepicker';
import RaisedButton from 'components/common/buttons/RaisedButton';
import useForm from 'hooks/useForm';
import TextInput from 'components/common/inputs/TextInput';
import FlexSection from 'components/common/FlexSection';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';
import Tooltip from 'components/common/Tooltip';

export interface OwnProps {
  onSave: (simulatorValues: SimulatorValues) => void;
  simulationValues: SimulatorValues | undefined;
  urlParams: string | undefined;
}

type Props = OwnProps & CommonModalProps;

const LoanPlanComputationModal: React.FC<Props> = ({
  hideModal,
  onSave,
  simulationValues,
  urlParams
}) => {
  const setDate = (date: Date | string | null): Date | null => {
    if (isNil(date) || isEmpty(date)) return null;

    return new Date(date);
  };

  const setDefaultState = (): SimulatorValues => {
    return !isNil(simulationValues)
      ? {
          ...simulationValues,
          final_repayment_date: setDate(simulationValues.final_repayment_date),
          current_date: setDate(simulationValues.current_date),
          start_date: setDate(simulationValues.start_date)
        }
      : defaultFormValues(urlParams);
  };

  const submit = () => {
    const simulatorValues = {
      frequency: values.frequency,
      sequence: values.sequence,
      period_count: values.period_count,
      final_repayment_date: handleDate(values.final_repayment_date),
      current_date: handleDate(values.current_date),
      start_date: handleDate(values.start_date),
      amount: values.amount,
      arbitrary_fee: values.arbitrary_fee,
      interest_rate: values.interest_rate,
      fee_rate: values.fee_rate,
      expected_transactions_adjustments:
        values.expected_transactions_adjustments,
      actual_transactions: values.actual_transactions
    };
    onSave(simulatorValues);
    hideModal();
  };

  const handleReset = () => {
    const values = defaultFormValues(urlParams);
    const params = {
      ...values,
      start_date: handleDate(values['start_date']),
      current_date: handleDate(values['current_date']),
      final_repayment_date: handleDate(values['final_repayment_date'])
    };
    onSave(params);
    resetForm();
    hideModal();
  };

  const {
    handleChange,
    handleChanges,
    handleSubmit,
    resetForm,
    values,
    errors
  } = useForm({
    defaultState: setDefaultState(),
    submitAction: submit
  });

  return (
    <div
      className={`ds-modal _open ${css(GlobalStyles.modal)}`}
      data-testid="loan-plan-computation-modal"
      onClick={hideModal}
    >
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-db600">
            <h1 className="subhead -white">Update Computation Values</h1>
          </section>
          <ModalTopCloseButton hideModal={hideModal} />
          <FlexSection classes="_marginLeft--normal _marginTop--normal">
            <div className="ds-input _marginRight--small">
              <span>Frequency</span>
              <DynamicSelect
                name="frequency"
                dataTestId="frequency-select"
                defaultValue={values.frequency}
                onChange={(value: Value) => {
                  handleChanges({
                    field: 'frequency',
                    value: value
                  });
                }}
                options={FREQUENCY_OPTIONS}
              />
            </div>
            <div className="ds-input _marginRight--small">
              <span>Arbitrary Fee</span>
              <TextInput
                value={values.arbitrary_fee}
                name="arbitrary_fee"
                data-testid="arbitrary-fee-input"
                onChange={handleChange}
                placeholder="Enter arbitrary fee"
                error={errors.arbitrary_fee}
                showError
              />
            </div>
          </FlexSection>
          <FlexSection classes="_marginLeft--normal _marginTop--normal">
            <div className="ds-input _marginRight--small">
              <span>Sequence</span>
              <DynamicSelect
                name="sequence"
                dataTestId="sequence-select"
                defaultValue={values.sequence}
                onChange={(value: Value) => {
                  handleChanges({
                    field: 'sequence',
                    value: value
                  });
                }}
                options={SEQUENCE_OPTIONS}
              />
            </div>
            <div className="ds-input _marginRight--small">
              <span>Period Count</span>
              <DynamicSelect
                name="period_count"
                dataTestId="period-count-select"
                defaultValue={values.period_count}
                onChange={(value: Value) => {
                  handleChanges({
                    field: 'period_count',
                    value: value
                  });
                }}
                options={PERIOD_COUNT_OPTIONS}
              />
            </div>
          </FlexSection>
          <FlexSection classes="_marginLeft--normal _marginTop--normal">
            <div className="ds-input _marginRight--small">
              <span>Current Date</span>
              <ReactDatePicker
                selected={values.current_date}
                dataTestId="current-date"
                onChange={value => {
                  handleChanges({
                    field: 'current_date',
                    value: value
                  });
                }}
                shouldCloseOnSelect={true}
                isClearable
                error={errors.current_date}
                showError
              />
            </div>
            <div className="ds-input _marginRight--small">
              <span>Start Date</span>
              <ReactDatePicker
                selected={values.start_date}
                dataTestId="start-date"
                onChange={value => {
                  handleChanges({
                    field: 'start_date',
                    value: value
                  });
                }}
                shouldCloseOnSelect={true}
                isClearable
                error={errors.start_date}
                showError
              />
            </div>
          </FlexSection>
          <FlexSection classes="_marginLeft--normal _marginTop--normal">
            <div className="ds-input _marginRight--small">
              <span>Final Repayment Date</span>
              <ReactDatePicker
                selected={values.final_repayment_date}
                dataTestId="final-repayment-date"
                onChange={value => {
                  handleChanges({
                    field: 'final_repayment_date',
                    value: value
                  });
                }}
                shouldCloseOnSelect={true}
                isClearable
                error={errors.final_repayment_date}
                showError
              />
            </div>
            <div className="ds-input _marginRight--small">
              <span>Amount</span>
              <TextInput
                value={values.amount}
                name="amount"
                data-testid="amount-input"
                onChange={handleChange}
                placeholder="Enter amount"
                error={errors.amount}
                showError
              />
              <small>ex. 1000000</small>
            </div>
          </FlexSection>
          <FlexSection classes="_marginLeft--normal _marginTop--normal">
            <div className="ds-input _marginRight--small">
              <span>Interest Rate</span>
              <TextInput
                value={values.interest_rate}
                name="interest_rate"
                data-testid="interest-rate-input"
                onChange={handleChange}
                onClearAction={() =>
                  handleChanges({
                    field: 'interest_rate',
                    value: ''
                  })
                }
                placeholder="Enter interest rate"
                error={errors.interest_rate}
                showError
                clearable
              />
              <small>ex. 0.01</small>
            </div>
            <div className="ds-input _marginRight--small">
              <span>Fee Rate</span>
              <TextInput
                value={values.fee_rate}
                name="fee_rate"
                data-testid="fee-rate-input"
                onChange={handleChange}
                onClearAction={() =>
                  handleChanges({
                    field: 'fee_rate',
                    value: ''
                  })
                }
                placeholder="Enter fee rate"
                error={errors.fee_rate}
                showError
                clearable
              />
              <small>ex. 0.03</small>
            </div>
          </FlexSection>
          <div className="_marginLeft--normal _marginTop--normal _marginRight--small">
            <div className="ds-input">
              <span>Expected Transactions Adjustments</span>
              <TextInput
                value={values.expected_transactions_adjustments}
                name="expected_transactions_adjustments"
                data-testid="expected-transactions-adjustments-input"
                onChange={handleChange}
                onClearAction={() =>
                  handleChanges({
                    field: 'expected_transactions_adjustments',
                    value: ''
                  })
                }
                placeholder="Enter expected transactions adjustments"
                error={errors.expected_transactions_adjustments}
                showError
                clearable
              />
              <small>ex. make:2020-05-01:5000,move:2020-06-01:2020-06-03</small>
            </div>
          </div>
          <div className="_marginLeft--normal _marginTop--normal _marginRight--small">
            <div className="ds-input _marginRight--small">
              <Tooltip
                title={'Actual Transactions'}
                description={
                  'These are the transaction types that you can use: (payment, waiver, data_fix) <br/> <br/> yyyy-mm-dd:payment:-amount <br/> yyyy-mm-dd:waiver:-amount <br/> yyyy-mm-dd:data_fix:-amount'
                }
                position="right"
                dataTestId={`actual-transaction-samples`}
              >
                <span>
                  Actual Transactions <b>(Hover me for more info)</b>{' '}
                </span>
              </Tooltip>

              <TextInput
                value={values.actual_transactions}
                name="actual_transactions"
                data-testid="actual-transactions-input"
                onChange={handleChange}
                onClearAction={() =>
                  handleChanges({
                    field: 'actual_transactions',
                    value: ''
                  })
                }
                placeholder="Enter actual transactions"
                error={errors.actual_transactions}
                showError
                clearable
              />
              <small>ex. 2020-05-01:payment:-50000</small>
            </div>
          </div>
          <div className="_padding--normal">
            <div className="ds-buttonGroup _spacedButtons">
              <span className="_marginRight--small">
                <RaisedButton
                  type="primary"
                  dataTestId="simulate-button"
                  action={() => {
                    handleSubmit();
                  }}
                >
                  <span>Compute</span>
                </RaisedButton>
              </span>
              <span className="_marginRight--small">
                <RaisedButton
                  type="danger"
                  dataTestId="reset-button"
                  action={handleReset}
                >
                  <span>Reset</span>
                </RaisedButton>
              </span>
              <RaisedButton
                type="secondary"
                dataTestId="cancel-button"
                action={hideModal}
              >
                <span>Cancel</span>
              </RaisedButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanPlanComputationModal;
