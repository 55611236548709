import React from 'react';
import { css } from 'aphrodite';
import { navigate } from '@reach/router';

import { CommonModalProps } from 'interfaces/modal';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';
import RaisedButton from 'components/common/buttons/RaisedButton';

export interface OwnProps {
  leadId: string;
}

type Props = OwnProps & CommonModalProps;

const ReturnToLoanPlanPageModal: React.FC<Props> = ({ hideModal, leadId }) => {
  function onConfirmClick() {
    hideModal();
    navigate(`/leads/${leadId}/loan_plans`);
  }

  return (
    <div
      onClick={hideModal}
      className={`ds-modal _open ${css(GlobalStyles.modal)}`}
      data-testid="return-to-loan-plan-page-modal"
    >
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-db600">
            <h1 className="subhead -white">Return to previous page</h1>
          </section>
          <ModalTopCloseButton hideModal={hideModal} />
          <section className="cardSection">
            <div className="_padding--large">
              <p className="label _alignCenter _fontSize--xlarge _bold">
                Are you sure you want to go back to the previous page? The
                changes you made will be lost.
              </p>
            </div>
          </section>
          <div className="_padding--normal">
            <div className="ds-buttonGroup _spacedButtons _justifyContent-center">
              <span className="_marginRight--small">
                <RaisedButton
                  type="danger"
                  dataTestId="cancel-button"
                  action={hideModal}
                >
                  Cancel
                </RaisedButton>
              </span>
              <span className="_marginRight--small">
                <RaisedButton
                  type="primary"
                  dataTestId="previous-page-confirm-button"
                  action={onConfirmClick}
                >
                  {"Yes, I'm sure"}
                </RaisedButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnToLoanPlanPageModal;
