import React from 'react';

import { ModalContext as Context, ModalProps } from 'interfaces/modal';
import { ModalComponent } from 'components/modals/ModalComponents';

export const defaultContextValue: Context = {
  type: undefined,
  modalProps: undefined,
  setModalProps: (props: ModalProps) => props,
  setType: (type: ModalComponent) => type
};

const ModalContext = React.createContext<Context>(defaultContextValue);

export default ModalContext;
