/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useRef } from 'react';
import isNil from 'lodash-es/isNil';

import { SelectOption } from 'interfaces/global';
import { StyleSheet, css } from 'aphrodite';
import Select, { components, OptionProps, MultiValueProps } from 'react-select';
import {
  borderColor,
  errorColor,
  primaryColor,
  secondaryColor,
  white
} from '../styles/GlobalStyles';
import FlexSection from '../FlexSection';

export type Value = string | string[] | null;

interface Props {
  dataTestId: string;
  defaultValue?: Value;
  onChange: (value: Value) => void;
  options: SelectOption[];
  error?: string;
  isClearable?: boolean;
  disabled?: boolean;
  name?: string;
  isMulti?: boolean;
  inputId?: string;
  styles?: Record<string, any>;
  isLoading?: boolean;
  fetchOptions?: () => void;
  showError?: boolean;
}

const Styles = StyleSheet.create({
  transparent: {
    background: 'transparent'
  },
  checkBox: {
    color: primaryColor
  },
  checkboxLabel: {
    background: 'transparent',
    padding: 0,
    border: 0
  },
  selectAllOption: {
    borderBottom: `1px solid ${borderColor}`,
    marginBottom: '10px'
  },
  multiValueContainer: {
    backgroundColor: primaryColor,
    color: 'white',
    padding: '4px 8px 4px 8px',
    marginBottom: '6px',
    borderRadius: '4px',
    marginTop: '6px',
    fontSize: '12px'
  }
});

const commonStyles = {
  control: (styles: any) => ({
    ...styles,
    boxShadow: 'none !important',
    backgroundColor: white
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      fontWeight: isSelected ? 'bold' : '',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? white
        : isFocused
        ? secondaryColor
        : null,
      color: isDisabled ? '#ccc' : isSelected ? primaryColor : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : white)
      }
    };
  }
};

const commonStylesDisabled = {
  ...commonStyles,
  control: (styles: any) => ({ ...styles, backgroundColor: borderColor })
};

const commonStylesError = {
  ...commonStyles,
  control: (styles: any) => ({
    ...styles,
    backgroundColor: white,
    boxShadow: 'none !important',
    border: `2px solid ${errorColor} !important`
  })
};

const multiStyles = {
  ...commonStyles,
  multiValue: (styles: any) => {
    return {
      ...styles,
      color: primaryColor,
      backgroundColor: primaryColor,
      borderRadius: '4px'
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: 'white',
    fontSize: '6px'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: secondaryColor,
      color: primaryColor
    }
  })
};

const singleStyles = {
  ...commonStyles,
  input: (styles: any) => ({ ...styles }),
  placeholder: (styles: any) => ({ ...styles }),
  singleValue: (styles: any) => ({ ...styles })
};

const Checkbox = ({ checked }: { checked: boolean }) => {
  return (
    <>
      <div className={`ds-checkbox ${css(Styles.transparent)}`}>
        <input
          type="checkbox"
          checked={checked}
          className="checkbox"
          name="checkbox"
          value="checkbox"
          onChange={() => null}
        />
        <label
          id="test-label"
          htmlFor="checkbox"
          className={`ds-btn ${css(Styles.checkboxLabel)}`}
        >
          <i className={`materialIcon --micro _off ${css(Styles.checkBox)}`}>
            check_box_outline_blank
          </i>
          <i
            className={`materialIcon --micro _on ${css(Styles.checkBox)}`}
            style={{ color: primaryColor }}
          >
            check_box
          </i>
        </label>
      </div>
    </>
  );
};

const Option = (props: OptionProps<any>) => {
  return (
    <div
      className={
        props.label === selectAllOption.label ? css(Styles.selectAllOption) : ''
      }
    >
      <components.Option {...props}>
        <FlexSection>
          <Checkbox checked={props.isSelected} />
          <label
            data-testid={props.label}
            className={`_marginTop--xxxsmall ${
              props.isSelected ? 'selected' : ''
            }`}
          >
            {props.label}
          </label>
        </FlexSection>
      </components.Option>
    </div>
  );
};

// Let's us not delete this, we might use it again
// const MultiValue = (props: MultiValueProps<any>) => {
//   return (
//     <components.MultiValue {...props}>{props.data.label}</components.MultiValue>
//   );
// };

const selectAllOption = {
  value: 'all',
  label: 'All Selected'
};

const MultiValueLimited = ({ children, ...props }: MultiValueProps<any>) => {
  const values = props.getValue();
  let valueLabel = '';

  if (values.length > 0 && !isNil(props.selectProps.getOptionLabel)) {
    const selected = values
      .map((val: Record<string, any>) => val.value)
      .sort((a: any, b: any) => a - b);

    for (let x = 0; x <= selected.length - 1; x++) {
      if (x !== 0) valueLabel += ', ';
      valueLabel += selected[x];
    }
  }

  if (values.length > 2) {
    valueLabel = '';

    const selected = values.map((val: Record<string, any>) => val.value);

    valueLabel = selected.includes(selectAllOption.value)
      ? 'All Selected'
      : `${values.length} items selected`;
  }

  // Keep standard placeholder and input from react-select
  const childsToRender = React.Children.toArray(children).filter(
    (child: any) =>
      ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel && (
        <span className={css(Styles.multiValueContainer)}>{valueLabel}</span>
      )}
      {childsToRender}
    </components.ValueContainer>
  );
};

export const handleSubmitValue = (value: Value): Value => {
  if (value instanceof Array) {
    return value.filter((v: string) => v !== selectAllOption.value);
  }

  return value;
};

const DynamicSelect: React.FC<Props> = ({
  defaultValue,
  isClearable,
  isMulti,
  options,
  name,
  error,
  onChange,
  inputId,
  styles,
  isLoading,
  fetchOptions,
  dataTestId,
  disabled,
  showError
}) => {
  const valueRef = useRef(defaultValue);
  valueRef.current = defaultValue;

  function getOptions() {
    if (isLoading || options.length === 0) return [];
    return [selectAllOption, ...options];
  }

  function isAllSelected(): boolean {
    if (!isNil(valueRef.current) && valueRef.current instanceof Array) {
      return valueRef.current.includes('all');
    }

    return false;
  }

  function isOptionSelected(option: any) {
    if (
      !isNil(valueRef.current) &&
      valueRef.current instanceof Array &&
      valueRef.current.length > 0
    ) {
      return (
        valueRef.current.some((value: string) => value === option.value) ||
        isAllSelected()
      );
    } else {
      return false;
    }
  }

  // We use any here since the value of react select expects
  // a type that conflicts what we need.
  // TODO: recheck this
  function processValue(): any {
    let value: SelectOption | SelectOption[] | null;
    let selected: SelectOption | undefined;

    value = isMulti ? [] : options[0];

    if (isNil(valueRef.current) || valueRef.current.length === 0) {
      return value;
    }

    const allOptions = getOptions();

    if (!isNil(valueRef.current)) {
      // Handles single value
      if (!(valueRef.current instanceof Array)) {
        selected = allOptions.find(
          (option: SelectOption) =>
            valueRef.current &&
            option.value.toString() === valueRef.current.toString()
        );
        if (!isNil(selected)) value = selected;
      } else {
        // Handles multiple values
        if (isAllSelected()) {
          value = [selectAllOption, ...options];
        } else {
          const arr: SelectOption[] = [];
          valueRef.current.forEach((o: string) => {
            selected = allOptions.find(
              (option: SelectOption) =>
                !isNil(o) && option.value.toString() === o.toString()
            );
            if (!isNil(selected)) arr.push(selected);
          });
          value = arr;
          return value;
        }
      }
    }

    return value;
  }

  function handleChange(
    selectValue: SelectOption[] | SelectOption,
    meta: any
  ): void {
    let value: string | string[] | null = isMulti ? [] : null;

    if (isNil(selectValue)) {
      onChange(value);
    }

    if (selectValue instanceof Array) {
      const { action, option } = meta;
      const allOptions = getOptions();

      const selectedAll = (): boolean => {
        return (
          action === 'select-option' && option.value === selectAllOption.value
        );
      };

      const deSelectedAll = (): boolean => {
        return (
          action === 'deselect-option' && option.value === selectAllOption.value
        );
      };

      const allSelectedAndDeselectOther = (): boolean => {
        return (
          action === 'deselect-option' &&
          !isNil(valueRef.current) &&
          valueRef.current.includes('all')
        );
      };

      if (selectedAll()) value = allOptions.map((o: SelectOption) => o.value);
      else if (deSelectedAll()) value = [];
      else if (allSelectedAndDeselectOther()) {
        value = options
          .filter(({ value }) => value !== option.value && value !== 'all')
          .map((option: SelectOption) => option.value);
      } else {
        value =
          selectValue.length !== 0
            ? selectValue.map((option: SelectOption) => option.value)
            : [];
      }
    } else if (selectValue instanceof Object) {
      value = selectValue.value;
    }

    valueRef.current = value;
    onChange(value);
  }

  function onMenuOpen(): void {
    if (!isNil(fetchOptions) && options.length === 0) fetchOptions();
  }

  function selectStyles() {
    let styles = {};

    if (isMulti) {
      styles = { ...styles, ...multiStyles };
      if (disabled) styles = { ...styles, ...commonStylesDisabled };
      if (!isNil(error)) styles = { ...styles, ...commonStylesError };
    }

    return styles;
  }

  if (isMulti) {
    return (
      <div data-testid={dataTestId}>
        <Select
          placeholder=""
          value={processValue()}
          name={name}
          inputId={inputId}
          closeMenuOnSelect={false}
          isMulti={true}
          components={{
            Option,
            ValueContainer: MultiValueLimited
          }}
          options={getOptions()}
          onChange={handleChange}
          hideSelectedOptions={false}
          styles={{ ...selectStyles() }}
          isLoading={isLoading}
          onMenuOpen={onMenuOpen}
          isOptionSelected={isOptionSelected}
          isDisabled={disabled}
        />

        {!isNil(error) && showError && (
          <p className="-danger _marginTop--small _marginBottom--normal">
            {error}
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div data-testid={dataTestId}>
        <Select
          placeholder=""
          value={processValue()}
          options={options}
          name={name}
          inputId={inputId}
          styles={{ ...singleStyles, ...styles }}
          isClearable={isClearable}
          onChange={handleChange}
          isLoading={isLoading}
          onMenuOpen={onMenuOpen}
          isDisabled={disabled}
        />
      </div>
    );
  }
};

export default DynamicSelect;
