import React from 'react';

import './App.css';
import ApplicationProvider from 'providers/ApplicationProvider';
import ModalProvider from 'providers/ModalProvider';

interface Props {
  children: React.ReactElement;
}

const App = ({ children }: Props): React.ReactElement => {
  return (
    <ApplicationProvider>
      <ModalProvider>{children}</ModalProvider>
    </ApplicationProvider>
  );
};

export default App;
