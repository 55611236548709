/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import isNil from 'lodash-es/isNil';

import { CommonReturnProps } from 'interfaces/apiFetch';
import { METHODS } from 'utils/api';
import { cancelPaymentPlanEndpoint } from 'api/restructuring';

import useFetch from '../useFetch';

interface CancelPaymentPlanReturn extends CommonReturnProps {
  cancelPaymentPlan: (loanPlanIds: string, leadId: string) => void;
}

interface Props {
  onSuccessCallback?: () => void;
  onFailCallback?: () => void;
}

const useCancelPaymentPlan = (props?: Props): CancelPaymentPlanReturn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = () => {
    if (!isNil(props) && !isNil(props.onSuccessCallback)) {
      props.onSuccessCallback();
    }
  };

  const onFailCallback = () => {
    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const cancelPaymentPlan = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (loanPlanIds: string, leadId: string) => {
      executeRequest({
        path: cancelPaymentPlanEndpoint(leadId, loanPlanIds),
        method: METHODS.DELETE,
        shouldExecuteRequest: true
      });
    },
    [executeRequest]
  );

  return {
    isFailed,
    isLoading,
    isSuccessful,
    cancelPaymentPlan,
    error
  };
};

export default useCancelPaymentPlan;
