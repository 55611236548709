import React, { useState } from 'react';
import { css } from 'aphrodite';
import isNil from 'lodash-es/isNil';
import ReactHtmlParser from 'react-html-parser';

import { HttpRequestError } from 'utils/error';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import FlexSection from './FlexSection';

type NoticeType = 'success' | 'warning' | 'error' | 'info';

export interface Props {
  type: NoticeType;
  message: string | React.ReactElement | null;
  listView?: boolean;
  error?: HttpRequestError;
}

const Notice: React.FC<Props> = ({ type, message, error, listView }) => {
  let noticeClass = '';
  let iconClass = '';

  const [visible, setVisible] = useState(true);

  switch (type) {
    case 'error':
      noticeClass = `${css(GlobalStyles.errorNotice)}`;
      iconClass = '-danger';
      break;
    case 'warning':
      noticeClass = `${css(GlobalStyles.warningNotice)}`;
      iconClass = '-warning';
      break;
    case 'info':
      noticeClass = `${css(GlobalStyles.infoNotice)}`;
      iconClass = '-primary';
      break;
    default:
      noticeClass = `${css(GlobalStyles.successNotice)}`;
      iconClass = '-success';
      break;
  }

  if (!visible) {
    return null;
  }

  const renderMessage = () => {
    if (typeof message === 'string') {
      return <p>{ReactHtmlParser(message)}</p>;
    }

    return <div>{message}</div>;
  };

  const renderContent = () => {
    if (listView) {
      return (
        <>
          <FlexSection classes="_justifyContent-space-between _paddingTop--normal _paddingLeft--normal">
            <div className="_padding--normal _paddingTop--none">
              {renderMessage()}
            </div>
            <div className="icon">
              <i
                onClick={() => setVisible(false)}
                className={`_margin--none materialIcon --small ${iconClass}`}
                data-testid="close-icon"
              >
                cancel_outline
              </i>
            </div>
          </FlexSection>
        </>
      );
    }

    return (
      <>
        <FlexSection classes="_justifyContent-space-between _paddingTop--normal _paddingLeft--normal">
          <>
            {!isNil(error) && (
              <h1 className="headline -danger">Error: {error.status}</h1>
            )}
          </>
          <div className="icon">
            <i
              onClick={() => setVisible(false)}
              className={`_margin--none materialIcon --small ${iconClass}`}
              data-testid="close-icon"
            >
              cancel_outline
            </i>
          </div>
        </FlexSection>
        <div className="_padding--normal _paddingTop--none">
          {renderMessage()}
        </div>
      </>
    );
  };

  return (
    <div
      className={`_marginBottom--normal ${noticeClass}`}
      data-testid="notice"
    >
      {renderContent()}
    </div>
  );
};

export default Notice;
