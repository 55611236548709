import React from 'react';

import useModalContextUpdater from 'hooks/modal/useModalContextUpdater';
import ModalContext from 'contexts/ModalContext';
import Modal from 'components/modals/Modal';

interface Props {
  children: React.ReactElement;
}

const ModalProvider: React.FC<Props> = ({ children }) => {
  const updater = useModalContextUpdater();
  return (
    <ModalContext.Provider value={updater}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
