/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import isNil from 'lodash-es/isNil';

import { CommonReturnProps } from 'interfaces/apiFetch';
import { METHODS } from 'utils/api';
import { generateNewLoanPlanEndpoint } from 'api/restructuring';

import useFetch from '../useFetch';

interface GenerateNewLoanPlanReturn extends CommonReturnProps {
  generateLoanPlan: (leadId: string, paymentPlanId: number | undefined) => void;
}

interface Props {
  onSuccessCallback?: () => void;
  onFailCallback?: () => void;
}

const useGenerateNewLoanPlan = (props?: Props): GenerateNewLoanPlanReturn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (response: any) => {
    if (!isNil(response)) {
      if (!isNil(props) && !isNil(props.onSuccessCallback)) {
        props.onSuccessCallback();
      }
    }
  };

  const onFailCallback = () => {
    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const generateLoanPlan = useCallback(
    (leadId: string, paymentPlanId: number | undefined) => {
      executeRequest({
        path: generateNewLoanPlanEndpoint(leadId, paymentPlanId),
        method: METHODS.POST,
        shouldExecuteRequest: true,
        payload: {}
      });
    },
    [executeRequest]
  );

  return {
    isFailed,
    isLoading,
    isSuccessful,
    generateLoanPlan,
    error
  };
};

export default useGenerateNewLoanPlan;
