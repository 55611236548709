import React from 'react';
import { css } from 'aphrodite';

import { CommonModalProps } from 'interfaces/modal';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';
import RaisedButton from 'components/common/buttons/RaisedButton';

export interface OwnProps {
  onConfirm: () => void;
}

type Props = OwnProps & CommonModalProps;

const RestructuringResetConfirmationModal: React.FC<Props> = ({
  hideModal,
  onConfirm
}) => {
  return (
    <div
      onClick={hideModal}
      className={`ds-modal _open ${css(GlobalStyles.modal)}`}
      data-testid="restructuring-reset-confirmation-modal"
    >
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-db600">
            <h1 className="subhead -white">Reset Form</h1>
          </section>
          <ModalTopCloseButton hideModal={hideModal} />
          <section className="cardSection">
            <div className="_padding--large">
              <p className="label _alignCenter _fontSize--xlarge _bold">
                Are you sure you want to reset the form? This will return the
                values you modified to their original amounts.
              </p>
            </div>
          </section>
          <div className="_padding--normal">
            <div className="ds-buttonGroup _spacedButtons _justifyContent-center">
              <span className="_marginRight--small">
                <RaisedButton
                  type="danger"
                  dataTestId="cancel-button"
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    hideModal(event);
                  }}
                >
                  Cancel
                </RaisedButton>
              </span>
              <span className="_marginRight--small">
                <RaisedButton
                  type="primary"
                  dataTestId="reset-confirm-button"
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    hideModal(event);
                    onConfirm();
                  }}
                >
                  {"Yes, I'm sure"}
                </RaisedButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestructuringResetConfirmationModal;
