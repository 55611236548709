import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const Styles = StyleSheet.create({
  ribbon: {
    width: '150px',
    height: '150px',
    overflow: 'hidden',
    position: 'absolute'
  },

  ribbonSpan: {
    zIndex: 1000,
    position: 'absolute',
    display: 'block',
    width: '225px',
    padding: '15px 0',
    backgroundColor: '#003a70',
    boxShadow: '0 5px 10px rgba(0,0,0,.1)',
    color: '#fff',
    textShadow: '0 1px 1px rgba(0,0,0,.2)',
    textTransform: 'uppercase',
    textAlign: 'center',
    left: '-25px',
    top: '30px',
    transform: 'rotate(45deg)',
    paddingLeft: '30px'
  },

  ribbonTopRight: {
    top: '-10px',
    right: '0',
    ':before': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      top: 0,
      left: 0
    },
    ':after': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      bottom: 0,
      right: 0
    }
  }
});

const EnvironmentRibbon: React.FC = (): React.ReactElement | null => {
  const environments = ['staging', 'preprod', 'mirror'];
  let currentEnvironment = '';
  let showRibbon = false;

  const validateEnvironment = () => {
    const parsedUrl = new URL(window.location.href);
    const hostname = parsedUrl.hostname;

    if (hostname.match(/localhost/)) {
      showRibbon = true;
      currentEnvironment = 'DEVELOPMENT';
      return;
    }

    // Dynamically check if hostname is supported
    const hostnameItems = hostname.split('.');
    hostnameItems.some(i => {
      const foundIndex = environments.indexOf(i);
      if (foundIndex !== -1) {
        showRibbon = true;
        currentEnvironment = environments[foundIndex].toUpperCase();
      }
      return null;
    });
  };

  validateEnvironment();

  if (!showRibbon) return null;

  return (
    <div
      className={css(Styles.ribbon, Styles.ribbonTopRight)}
      data-testid="environment-ribbon"
    >
      <span className={css(Styles.ribbonSpan)}>{currentEnvironment}</span>
    </div>
  );
};

export default EnvironmentRibbon;
