/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';

import { CommonReturnProps } from 'interfaces/apiFetch';
import { METHODS } from 'utils/api';
import { RestructuringFormValues } from 'interfaces/restructuring';
import {
  savePaymentPlanEndpoint,
  updatePaymentPlanEndpoint
} from 'api/restructuring';

import useFetch from '../useFetch';

interface SavePaymentPlanReturn extends CommonReturnProps {
  savePaymentPlan: (params: RestructuringFormValues, leadId: string) => void;
  updatePaymentPlan: (
    params: RestructuringFormValues,
    leadId: string,
    paymentPlanId: string | number | undefined
  ) => void;
}

interface Props {
  onSuccessCallback?: (paymentPlanId: number) => void;
  onFailCallback?: () => void;
}

const useFetchLoanPlan = (props?: Props): SavePaymentPlanReturn => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessCallback = (response: any) => {
    if (!isNil(response) && !isEmpty(response.data)) {
      if (!isNil(props) && !isNil(props.onSuccessCallback)) {
        props.onSuccessCallback(parseInt(response.data.id));
      }
    }
  };

  const onFailCallback = () => {
    if (!isNil(props) && !isNil(props.onFailCallback)) {
      props.onFailCallback();
    }
  };

  const {
    isLoading,
    error,
    isSuccessful,
    isFailed,
    executeRequest
  } = useFetch({ onSuccessCallback, onFailCallback });

  const savePaymentPlan = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: RestructuringFormValues, leadId) => {
      executeRequest({
        path: savePaymentPlanEndpoint(leadId),
        method: METHODS.POST,
        shouldExecuteRequest: true,
        payload: params
      });
    },
    [executeRequest]
  );

  const updatePaymentPlan = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (
      params: RestructuringFormValues,
      leadId: string,
      paymentPlanId: string | number | undefined
    ) => {
      if (isNil(paymentPlanId)) return;

      executeRequest({
        path: updatePaymentPlanEndpoint(leadId, paymentPlanId),
        method: METHODS.PUT,
        shouldExecuteRequest: true,
        payload: params
      });
    },
    [executeRequest]
  );

  return {
    isFailed,
    isLoading,
    isSuccessful,
    savePaymentPlan,
    updatePaymentPlan,
    error
  };
};

export default useFetchLoanPlan;
