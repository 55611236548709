import React from 'react';

interface Props {
  hideModal: (event?: React.MouseEvent<HTMLElement>) => void;
}

const ModalTopCloseButton: React.FC<Props> = ({ hideModal }) => {
  return (
    <div data-testid="modal-close">
      <button
        className="ds-btn -secondary --micro _icon _rounded _closeModal"
        data-testid="modal-close-button"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          hideModal(event);
        }}
      >
        <i
          className="materialIcon"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            hideModal(event);
          }}
        >
          close
        </i>
      </button>
    </div>
  );
};

export default ModalTopCloseButton;
