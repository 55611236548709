import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import isNil from 'lodash-es/isNil';
import { css } from 'aphrodite';

import GlobaStyles from 'components/common/styles/GlobalStyles';

interface OwnProps {
  error?: string;
  showError?: boolean;
  dataTestId: string;
}

export type Props = OwnProps & ReactDatePickerProps;

const ReactDatePicker: React.FC<Props> = props => {
  let classes = `${css(GlobaStyles.pickerWithoutError)}`;
  const {
    error,
    showError,
    dataTestId,
    isClearable,
    disabled,
    ...attr
  } = props;

  if (error) {
    classes = `${css(GlobaStyles.pickerWithError)}`;
  }

  if (disabled) {
    classes = `${css(GlobaStyles.disabledPicker)}`;
  }

  const custom = () => {
    return (
      <input className={`${classes}`} data-testid={dataTestId} type="text" />
    );
  };

  return (
    <>
      <DatePicker
        {...attr}
        dateFormat="MM-dd-yyyy"
        placeholderText="DD-MM-YYYY"
        customInput={custom()}
        isClearable={isClearable}
        disabled={disabled}
      />
      {!isNil(error) && showError && (
        <p className="-danger _marginBottom--normal _marginTop--xxsmall">
          {error}
        </p>
      )}
    </>
  );
};

export default ReactDatePicker;
