import isNil from 'lodash-es/isNil';

export interface User {
  id: number;
  email: string;
  name: string;
  permissions: Record<string, boolean>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const isFinanceUser = (user: User): boolean => {
  return user.permissions.view_finance_dashboard;
};

export const isPreCollectionsUser = (user: User): boolean => {
  return user.permissions.view_pre_collections_dashboard;
};

export const isCollectionsUser = (user: User): boolean => {
  return user.permissions.view_collections_dashboard;
};

export const isRiskopsUser = (user: User): boolean => {
  return user.permissions.view_riskops_dashboard;
};

export const canViewLoanPlans = (user: User): boolean => {
  if (isNil(user.permissions)) return false;

  return user.permissions.view_loan_plans;
};

export const canViewLoanOffers = (user: User): boolean => {
  return user.permissions.view_loan_offers;
};

export const canViewLedger = (user: User): boolean => {
  return user.permissions.view_ledger;
};

export const canViewSettings = (user: User): boolean => {
  return user.permissions.view_settings;
};

export const canDoActions = (user: User): boolean => {
  return (
    user.permissions.view_finance_dashboard ||
    user.permissions.view_pre_collections_dashboard ||
    user.permissions.view_collections_dashboard
  );
};
