import React from 'react';
import { css } from 'aphrodite';
import { navigate } from '@reach/router';

import { CommonModalProps } from 'interfaces/modal';

import GlobalStyles from 'components/common/styles/GlobalStyles';
import RaisedButton from 'components/common/buttons/RaisedButton';
import ModalTopCloseButton from 'components/modals/ModalTopCloseButton';
export interface OwnProps {
  loanPlanIds: Array<string>;
  loanPlanCount: number;
  leadId: string;
}

type Props = OwnProps & CommonModalProps;

const LoanPlanRestructureConfirmationModal: React.FC<Props> = ({
  hideModal,
  loanPlanIds,
  loanPlanCount,
  leadId
}) => {
  const loanPlanCountText = () => {
    return `You selected ${loanPlanIds.length} of ${loanPlanCount} loan plans for restructuring:`;
  };

  const onConfirm = () => {
    navigate(
      `/leads/${leadId}/restructuring?loan_plans=${loanPlanIds.join(',')}`
    );
  };

  return (
    <div
      onClick={hideModal}
      className={`ds-modal _open ${css(GlobalStyles.modal)}`}
      data-testid="loan-plan-restructure-confirmation-modal"
    >
      <div className="grid">
        <div className="ds-card">
          <section className="cardSection _backgroundColor-db600">
            <h1 className="-white _fontSize--xlarge _alignCenter">
              Restructure Loan Plans
            </h1>
          </section>
          <ModalTopCloseButton hideModal={hideModal} />
          <section className="cardSection">
            <div className="_padding--large">
              <p className="label _alignCenter _fontSize--xlarge _bold">
                Are you sure you want to restructure selected loan plans?
              </p>
              <p className="label _alignCenter _fontSize--xlarge _bold">
                {loanPlanCountText()}
              </p>
              <p className="label _alignCenter _fontSize--xlarge _bold">
                {loanPlanIds.join(', ')}
              </p>
            </div>
          </section>
          <div className="_padding--normal">
            <div className="ds-buttonGroup _spacedButtons _justifyContent-center">
              <span className="_marginRight--small">
                <RaisedButton
                  type="danger"
                  dataTestId="cancel-button"
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    hideModal(event);
                  }}
                >
                  Cancel
                </RaisedButton>
              </span>
              <span className="_marginRight--small">
                <RaisedButton
                  type="primary"
                  dataTestId="confirm-restructure-button"
                  action={(event: React.MouseEvent<HTMLElement>) => {
                    hideModal(event);
                    onConfirm();
                  }}
                >
                  {"Yes, I'm sure"}
                </RaisedButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanPlanRestructureConfirmationModal;
