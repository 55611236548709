import * as React from 'react';
import { Helmet } from 'react-helmet';

// We can handle all stuffe inside <head /> here
// We can also handle title per page here
// For now, we name everything as First Circle Loan Management System

const DocumentTitle: React.FC = () => {
  return (
    <Helmet
      defaultTitle="First Circle Loan Management System"
      link={[
        {
          rel: 'icon',
          href: '/favicon.ico'
        }
      ]}
    />
  );
};

export default DocumentTitle;
