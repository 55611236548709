import React from 'react';

import { ApplicationContext as Context } from 'interfaces/application';
import { User } from 'interfaces/user';
import { Company } from 'interfaces/company';

export const defaultContextValue: Context = {
  setCompany: (company: Company) => company,
  company: undefined,
  setUser: (user: User) => user,
  user: undefined,
  setIsUnauthorized: () => null,
  isUnauthorized: false
};

const ApplicationContext = React.createContext<Context>(defaultContextValue);

export default ApplicationContext;
