import React from 'react';

interface Props {
  children: React.ReactElement | React.ReactElement[];
  classes?: string;
  dataTestId?: string;
}

const FlexSection: React.FC<Props> = ({
  children,
  classes,
  dataTestId
}: Props) => {
  return (
    <div data-testid={dataTestId} className={`_displayFlex ${classes}`}>
      {children}
    </div>
  );
};

export default FlexSection;
